<template>
  <component :is="contentComponent" />
</template>

<script>

const _m = () => import('@/pages/blogs/m.vue') //代码切割
const _pc = () => import('@/pages/blogs/pc.vue') //代码切割
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      contentComponent: null,
    };
  },
  computed: {
    ...mapGetters([
      'deviceType',
      "domainConfig"
    ])
  },
  metaInfo () {
    return {
      title: `Time Management Tips | ${this.domainConfig["IAmTitle"]} Blog`,
      meta: [
        {
          name: 'description',
          content: `Discover effective time management tips and strategies on the ${this.domainConfig["IAmTitle"]} blog. Learn how to optimize your daily schedule, boost productivity, and maintain self-discipline with our expert advice and practical guides.`,
        },
      ],
    };
  },
  created () {
    console.log(`deviceType - ${this.deviceType}`);
    if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
      this.contentComponent = _pc
      return
    }
    this.contentComponent = _m
  },
};
</script>
